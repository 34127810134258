import {
    SliderQuery as SourceSliderQuery
} from 'SourceQuery/Slider.query';

/** @namespace Pwa/Query/Slider/Query/SliderQuery */
export class SliderQuery extends SourceSliderQuery {
    _getSlideFields() {
        const fields = super._getSlideFields();

        return [...fields, 'slide_link'];
    }
}

export default new SliderQuery();
