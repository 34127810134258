import { connect } from 'react-redux';

import SliderQuery from 'Query/Slider.query';
import {
    mapDispatchToProps,
    mapStateToProps,
    SliderWidgetContainer as SourceSliderWidgetContainer
} from 'SourceComponent/SliderWidget/SliderWidget.container';
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Pwa/Component/SliderWidget/Container/SliderWidgetContainer */
export class SliderWidgetContainer extends SourceSliderWidgetContainer {
    requestSlider() {
        const { sliderId, showNotification } = this.props;

        fetchQuery(SliderQuery.getQuery({ sliderId }))
            .then(
                /** @namespace Pwa/Component/SliderWidget/Container/fetchQuery/then */
                ({ slider }) => this.setState({ slider })
            )
            .catch(
                /** @namespace Pwa/Component/SliderWidget/Container/fetchQuery/then/catch */
                (e) => showNotification('error', __('Error fetching Slider!'), e)
            );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderWidgetContainer);
